/* set the gradient background with a pseudo element on body. this supports mobile browsers */
.dashboard::before {
	content: '';
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -10;
}

/* ******************
   MAIN DASHBOARD STRUCTURE
   ****************** */
.dashboard .dashboard-main {
	transition: background 0.3s ease-in-out;
}
.dashboard .dashboard-main.bg-white {
	background: white;
}
.dashboard .dashboard-main .dashboard-wrap {
	display: flex;
}
.dashboard .dashboard-content {
	margin: 0 auto;
	flex: 1;
}
.dashboard .main-nav {
	display: none;
	position: fixed;
	z-index: 1;
}
.dashboard .dashboard-wrap {
	margin: 0 auto;
}
@media only screen and (max-width: 1040px) {
	.dashboard .main-nav {
		width: 9em;
	}
}
@media only screen and (max-width: 767px) {
	.dashboard .dashboard-content {
		margin-left: 0;
		margin-bottom: 4.5em;
		max-width: none;
	}
	.dashboard .main-nav {
		display: block;
		background: #fff;
		border-top: 1px solid #ddd;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 10;
	}

	.dashboard .main-nav ul {
		display: flex;
	}

	.dashboard .dashboard-wrap .main-nav ul li {
		flex: auto;
		margin: 0;
	}
	.dashboard .dashboard-wrap .main-nav ul li a {
		margin: 0;
		padding: 1.875em 0.25em 0.5em 0.25em;
		background-size: 1.5em;
		background-position: top;
		text-align: center;
		font-size: 13px;
		font-weight: 400;
	}
}

/* ******************
   SIDEBAR
   ****************** */
.dashboard .main-nav ul {
	padding-top: 0.75em;
}
.dashboard .main-nav ul li {
	margin-bottom: 2.75em;
	color: #828282;
}
.dashboard .main-nav ul li.active {
	color: #3d1a85;
}
.dashboard .main-nav ul li.active a {
	font-weight: 700;
}
.dashboard .main-nav ul li a {
	display: block;
	padding: 0.125em 0 0.125em 2.5em;
	margin-bottom: 2em;
	text-decoration: none;
	opacity: 0.85;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
	font-weight: 300;
}
.dashboard .main-nav ul li a:hover {
	opacity: 1;
}

/* .dashboard .main-nav ul li.home a {
	background-image: url('../../assets/main-nav/home.svg');
}

.dashboard .main-nav ul li.invest a {
	background-image: url('../../assets/main-nav/invest.svg');
}

.dashboard .main-nav ul li.retire a {
	background-image: url('../../assets/main-nav/retire.svg');
}

.dashboard .main-nav ul li.auto-stash a {
	background-image: url('../../assets/main-nav/auto-stash.svg');
}

.dashboard .main-nav ul li.learn a {
	background-image: url('../../assets/main-nav/learn.svg');
}

.dashboard .main-nav ul li.debit a {
	background-image: url('../../assets/main-nav/debit.svg');
	background-size: 1.9em;
}
.dashboard .main-nav ul li.active.home a {
	background-image: url('../../assets/main-nav/home-active.svg');
}

.dashboard .main-nav ul li.active.invest a {
	background-image: url('../../assets/main-nav/invest-active.svg');
}

.dashboard .main-nav ul li.active.retire a {
	background-image: url('../../assets/main-nav/retire-active.svg');
}

.dashboard .main-nav ul li.active.auto-stash a {
	background-image: url('../../assets/main-nav/auto-stash-active.svg');
}

.dashboard .main-nav ul li.active.debit a {
	background-image: url('../../assets/main-nav/debit-active.svg');
	background-size: 1.9em;
}

.dashboard .main-nav ul li.active.learn a {
	background-image: url('../../assets/main-nav/learn-active.svg');
}

.dashboard .main-nav ul li .new-badge {
	display: inline-block;
	width: 0.5em;
	height: 0.5em;
	margin-right: 0.25em;
	border-radius: 0.25em;
	background-color: #72ca5c;
} */
